<template>
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
        <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-large
        rounded="sm"
      >
        <!-- Register v1 -->
        <b-card class="mb-0">
          <b-link class="brand-logo">
            <h2 class="brand-text text-primary ml-1">
              CloudFit Pro
            </h2>
          </b-link>
  
          <b-card-title class="mb-1">
            訓練班報名 🚀
          </b-card-title>
          <b-card-text class="mb-2">
          </b-card-text>
  
          <!-- form -->
          <validation-observer ref="registerForm">
            <h4 class="brand-text text-primary ml-1" v-if="valid === false">Invalid Link</h4>
            <b-form v-if="insert === false && valid === true">
              <b-form-group
                label="商戶 Client Name"
                label-for="client"
              >
                <b-form-input
                  id="client_name"
                  v-model="class_detail.client_name"
                  trim
                  readonly
                />
  
              </b-form-group>
  
              <b-form-group
                label="訓練班名稱 Class Name"
                label-for="Class Name"
              >
                <b-form-input
                  id="name"
                  v-model="class_detail.name"
                  trim
                  readonly
                />
  
              </b-form-group>

              <b-form-group
                label="日期 Date"
                label-for="Class Date"
              >
                <b-form-input
                  id="class_date"
                  v-model="class_detail.class_date"
                  trim
                  readonly
                />
  
              </b-form-group>
              <b-form-group
                label="時間 Time"
                label-for="Class Time"
              >
                <b-form-input
                  id="class_date"
                  v-model="class_detail.start + ' - ' + class_detail.end"
                  trim
                  readonly
                />
  
              </b-form-group>
              <b-form-group
                label="地址 Address"
                label-for="Address"
              >
                <b-form-input
                  id="address"
                  v-model="class_detail.zh_address"
                  trim
                  readonly
                />
  
              </b-form-group>
              <b-form-group
                label="價錢 Price"
                label-for="Price"
              >
                <b-form-input
                  id="price"
                  style="color:red;font-weight: bold;"
                  v-model="class_detail.price"
                  trim
                  readonly
                />
  
              </b-form-group>
              <hr />
              <b-form-group
                 label="你的姓名 Your Name"
                 label-for="member_name"
               >
                 <b-form-input
                   v-model="client.name"
                   trim
                 />
  
               </b-form-group>
               <b-form-group
                 label="你的聯絡電話 Your Mobile"
                 label-for="mobile"
               >
                 <b-form-input
                   id="card"
                   v-model="client.mobile"
                   trim
                 />
  
               </b-form-group>
               <hr />
               <b-form-group
                 label="Credit Card Number"
                 label-for="card"
               >
                 <b-form-input
                   id="card"
                    v-mask="'#### #### #### ####'"
                   v-model="card.number"
                   trim
                 />
  
               </b-form-group>
  
             <b-form-group
               label="Expiry Date (YYYY-MM)"
               label-for="Expiry Date"
             >
               <b-form-input
                 id="expire"
                 v-mask="'####-##'"
                 v-model="card.expire"
                 trim
               />
  
             </b-form-group>
  
            <b-form-group
              label="CVC"
              label-for="cvc"
            >
              <b-form-input
                id="cvc"
                v-mask="'###'"
                v-model="card.cvc"
                trim
              />
  
            </b-form-group>
  
              <!-- checkbox -->
              <!--<b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  I agree to
                  <b-link>privacy policy & terms</b-link>
                </b-form-checkbox>
              </b-form-group>
  
               -->
              <b-button
                variant="primary"
                block
                type="button"
                @click="submit"
              >
                Submit
              </b-button>
              <b-row class="mt-2">
              <b-col><b-img style="width:100%" :src="require('@/assets/images/svg/mastercard.svg')" /></b-col>
              <b-col><b-img style="width:100%"  :src="require('@/assets/images/svg/unionpay.svg')" /></b-col>
              <b-col><b-img style="width:100%"  :src="require('@/assets/images/svg/visa.svg')" /></b-col>
              <b-col><b-img style="width:100%"  :src="require('@/assets/images/svg/ae.png')" /></b-col>
            </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      <!-- /Register v1 -->
       </b-overlay>
      </div>
    </div>
  </template>
  
  <script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import {
    BCard, BLink, BCardTitle, BCardText, BForm,BImg,BRow,BCol,
    BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,BOverlay
  } from 'bootstrap-vue'
  import router from '@/router'
  import VuexyLogo from '@core/layouts/components/Logo.vue'
  import { required, email } from '@validations'
  import { togglePasswordVisibility } from '@core/mixins/ui/forms'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  
  export default {
    components: {
      VuexyLogo,
      BImg,BRow,BCol,
      // BSV
      BCard,
      BLink,
      BCardTitle,
      router,
      BCardText,
      BForm,
      BButton,
      BOverlay,
      BFormInput,
      BFormGroup,
      BInputGroup,
      BInputGroupAppend,
      BFormCheckbox,
      // validations
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    watch: {
    "card.number": {
      handler(newValue, oldValue) {
        console.log(newValue.substring(0,1))
        switch (newValue.substring(0,1)) {
          case "3":
            this.credit_card_format = '#### ###### #####'
            this.cvc_format = '####'
            break;
          default:
          this.credit_card_format = '#### #### #### ####'
            this.cvc_format = '###'
        }
      }
    }
  },
    data() {
      return {
        regEmail: '',
        username: '',
        loading: false,
        valid : true,
        password: '',
        status: '',
        insert: false,
        client : {
            name: null,
            mobile: router.currentRoute.params.mobile
        },
        class_detail : {
          client_name: null,
          end: null,
          maximum: null,
          name: null,
          price: null,
          start: null,
          uuid: null,
          zh_address: null,
        },
        card: {
          number : null,
          expire : null,
          cvc: null,
        },
        // validation rules
        required,
        email,
      }
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
    },
    created() {
      this.get_class_detail()
    },
    methods: {
        get_class_detail() {
        this.$http.get(process.env.VUE_APP_API_BASE+`/class/`+ router.currentRoute.params.class_uuid + `/paylink/`)
          .then(res => {
            if (res.data.result === false ) {
              this.valid = false
            } else {
              this.class_detail = res.data.class
            }
          })
      },
      submit() {
      this.loading = true
      this.$http.post(process.env.VUE_APP_API_BASE+`/payment_gateway/stripe/${this.class_detail.center_id}/`, {
        card : this.card,
        client : this.client,
        class : this.class_detail,
        "method" : "paylink",
        "type" : "class"
      })
        .then(res => {
          if (res.data.result === true) {
            this.valid = false
            this.loading = false
            this.$swal({
              title: 'Payment Success 付款成功! ',
              text: '網上付款已經處理成功，你可以放心關掉此視窗.',
              icon: 'success',
              showConfirmButton : false,
              buttonsStyling: false,
              backdrop: false,
            })
          } else {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: res.data.message,
              },
            })
          }
        })
    },
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  </style>
  